<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column"></h3>
      <div class="card-toolbar">
        <a v-b-modal.modal-product-add class="btn btn-icon btn-light btn-sm">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Code/Plus.svg"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </a>
        <ProductAddModal />
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-vertical-center">
          <thead>
            <tr class="d-flex">
              <th class="col-2">Zdjęcie</th>
              <th class="col-3">Nazwa Produktu</th>
              <th class="col-1">Pojemność</th>
              <th class="col-2">Cena brutto</th>
              <th class="col-2">Kategoria</th>
              <th class="col-2 text-right pr-0">Akcje</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in dataToShow">
              <tr v-bind:key="i" class="d-flex"
                :class="item.isSlim ? 'text-danger' : 'text-dark-75'"
              >
                <td class="pl-0 col-2">
                  <ProductEditModal :product="item" />
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="symbol-label">
                      <img
                        :src="
                          item.imagePath !== ''
                            ? getBaseURL + item.imagePath
                            : ''
                        "
                        alt=""
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                </td>
                <td class="col-3">
                  <span
                    class="mb-1 font-size-lg"
                  >
                    {{ item.name }}
                  </span>
                </td>
                <td class="col-1">
                  <span
                    class="mb-1 font-size-lg"
                  >
                    {{ item.capacity /1000 }} {{ item.unit }}
                  </span>
                </td>
                <td class="col-2">
                  <span
                    class="d-block font-size-lg"
                  >
                    {{ transformPrice(item.priceBrutto) }} zł
                  </span>
                </td>
                <td class="col-2">
                  <span
                    class="d-block font-size-lg"
                  >
                    {{ item.category }}
                  </span>
                </td>
                <td class="text-right pr-0 col-2">
                  <a
                    class="btn btn-icon btn-light btn-sm mx-3"
                    @click="edit(item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    class="btn btn-icon btn-light btn-sm"
                    @click="remove(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  PRODUCT_SOFT_DELETE,
  PRODUCT_LIST
} from "@/core/services/store/product.module";
import ProductAddModal from "@/view/content/modal/ProductAddModal.vue";
import ProductEditModal from "@/view/content/modal/ProductEditModal.vue";
import { baseURL } from "@/core/services/api.service.js";
import Swal from "sweetalert2";
import currency from "currency.js";

export default {
  name: "product-table",
  components: { ProductAddModal, ProductEditModal },
  mounted() {
    this.$store.dispatch(PRODUCT_LIST);
  },
  methods: {
    remove(id) {
      Swal.fire({
        title: "Na pewno usunąć?",
        text: "Na pewno chcesz to zrobić?",
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(PRODUCT_SOFT_DELETE, { id });
          Swal.fire("Gotowe!", "Usunięto produkt.", "success");
        }
      });
    },
    edit(item) {
      this.$bvModal.show("modal-product-edit-" + item.id);
    },
    transformPrice(value) {
      return currency(value, { fromCents: true });
    }
  },
  computed: {
    ...mapGetters(["products"]),
    dataToShow() {
      return this.products;
      // return this.day;
    },
    getBaseURL() {
      return baseURL;
    }
  }
};
</script>
