<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="modal-product-add"
      ref="modal"
      title="Dodaj produkt"
      @show="resetForm"
      @cancel="resetForm"
      @ok="handleModalOk"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <b-form novalidate>
        <b-form-group
          label="Nazwa"
          label-for="name"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('name')"
        >
          <b-form-input id="input" v-model="$v.form.name.$model"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Cena brutto"
          label-for="priceBrutto"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('priceBrutto')"
        >
          <b-form-input
            type="number"
            min="0"
            step="0.01"
            id="priceBrutto"
            v-model="$v.form.priceBrutto.$model"
            @change="handleChangePrice($event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Stawka vat"
          label-for="vatValue"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('vatValue')"
        >
          <b-form-select
            id="vatValue"
            v-model="$v.form.vatValue.$model"
            @change="handleChangeVatValue($event)"
            :options="[
              { value: '', text: 'Wybierz' },
              { value: 0, text: '0%' },
              { value: 5, text: '5%' },
              { value: 8, text: '8%' },
              { value: 23, text: '23%' }
            ]"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Cena netto"
          label-for="price"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('price')"
        >
          <b-form-input
            type="number"
            id="price"
            min="0"
            step="0.01"
            v-model="$v.form.price.$model"
            readonly
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Kategoria"
          label-for="category"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('category')"
        >
          <b-form-select
            id="category"
            v-model="$v.form.category.$model"
            :options="[
              { value: '', text: 'Wybierz' },
              { value: 'podstawowa', text: 'Podstawowa' },
              { value: 'golebiowa', text: 'Gołębiowa' }
            ]"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Ilość"
          label-for="count"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('count')"
        >
          <b-form-select
            id="count"
            v-model="$v.form.count.$model"
            :options="[
              { value: '', text: 'Wybierz' },
              { value: '0.5L', text: '0.5 L' },
              { value: '0.5kg', text: '0.5 kg' },
              { value: '1L', text: '1 L' },
              { value: '1kg', text: '1 kg' },
              { value: '5L', text: '5 L' },
              { value: '5kg', text: '5 kg' },
              { value: '10L', text: '10 L' },
              { value: '20kg', text: '20 kg' },
              { value: '25kg', text: '25 kg' }
            ]"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Pojemność"
          label-for="capacity"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('capacity')"
        >
          <b-form-select
            id="capacity"
            v-model="$v.form.capacity.$model"
            :options="[
              { value: null, text: 'Wybierz' },
              { value: 500, text: '0.5' },
              { value: 1000, text: '1' },
              { value: 5000, text: '5' },
              { value: 10000, text: '10' },
              { value: 20000, text: '20' },
              { value: 25000, text: '25' }
            ]"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Jednostka"
          label-for="unit"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('unit')"
        >
          <b-form-select
            id="unit"
            v-model="$v.form.unit.$model"
            :options="[
              { value: '', text: 'Wybierz' },
              { value: 'KG', text: 'Kilogram' },
              { value: 'L', text: 'Litr' },
            ]"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Zdjęcie" label-for="file">
          <b-form-file
            id="file"
            @change="handleChangeFile($event)"
            accept="image/jpeg, image/png, image/gif"
            browse-text="Przeglądaj"
            placeholder="Nie wybrano pliku"
          ></b-form-file>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="form.isSlim" id="isSlim">
            Produkt Slim
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Waga"
          label-for="weight"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('weight')"
        >
          <b-form-input
            id="input"
            v-model="$v.form.weight.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { PRODUCT_ADD } from "@/core/services/store/product.module";
import { encode } from "@/core/plugins/base64.js";
import { baseURL } from "@/core/services/api.service.js";
import { required } from "vuelidate/lib/validators";
import currency from "currency.js";

export default {
  name: "product-add-modal",
  data() {
    return {
      form: {
        formState: null,
        name: "",
        price: 0,
        priceBrutto: 0,
        vatValue: "",
        count: "",
        capacity: 0,
        unit: "",
        weight: "",
        category: "",
        file: {
          encodedFile: "",
          extension: "",
          name: ""
        },
        isSlim: false
      }
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      price: {
        required
      },
      vatValue: {
        required
      },
      priceBrutto: {
        required
      },
      count: {
        required
      },
      capacity: {
        required
      },
      unit: {
        required
      },
      category: {
        required
      },
      weight: {
        required
      }
    }
  },
  methods: {
    resetForm() {
      this.form.name = "";
      this.form.price = 0;
      this.form.priceBrutto = 0;
      this.form.vatValue = 0;
      this.form.count = "";
      this.form.capacity = null;
      this.form.unit = "";
      this.form.category = "";
      this.form.isSlim = false;
      this.form.weight = "";
      this.form.formState = null;
    },
    validateField(field) {
      const { $dirty, $error } = this.$v.form[field];
      return $dirty ? !$error : null;
    },
    validateForm() {
      // console.log( this.$v )
      this.$v.form.$touch();
      this.form.formState = !this.$v.form.$invalid;
    },
    handleChangePrice(value) {
      let vatValue = this.form.vatValue;
      let priceBrutto = currency(value, { fromCents: false });
      let vat = vatValue / 100 + 1;
      this.form.price = currency(priceBrutto.intValue / vat, { fromCents: true }).value;
    },
    handleChangeVatValue(value) {
      let vatValue = Number(value);
      let priceBrutto = currency(this.form.priceBrutto, { fromCents: false });
      let vat = vatValue / 100 + 1;
      this.form.price = currency(priceBrutto.intValue / vat, { fromCents: true }).value;
    },
    handleChangeFile(event) {
      const file = event.target.files[0];
      encode(file).then(data => {
        this.form.file = {
          name: file.name,
          extension:
            file.name.substring(
              file.name.lastIndexOf(".") + 1,
              file.name.length
            ) || file.name,
          encodedFile: data.split(",")[1]
        };
      });
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.validateForm();
      if (this.form.formState) {
        let formToSend = this.form;
        formToSend.priceBrutto = currency(formToSend.priceBrutto).intValue;
        formToSend.price = currency(formToSend.price).intValue;
        this.$store.dispatch(PRODUCT_ADD, formToSend);
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("modal-product-add");
        });
      }
    }
  },
  mounted() {
  },
  computed: {
    getBaseURL() {
      return baseURL;
    }
  }
};
</script>
