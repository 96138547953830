var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal",attrs:{"id":'modal-product-edit-' + _vm.product.id,"title":"Edytuj produkt","ok-title":"Zaktualizuj","cancel-title":"Anuluj","no-close-on-backdrop":true,"no-close-on-esc":true},on:{"cancel":_vm.resetForm,"ok":_vm.handleModalOk,"shown":_vm.fillModal}},[_c('b-form',{attrs:{"novalidate":""}},[_c('b-form-group',{attrs:{"label":"Nazwa","label-for":"name","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('name')}},[_c('b-form-input',{attrs:{"id":"input"},model:{value:(_vm.$v.form.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.name, "$model", $$v)},expression:"$v.form.name.$model"}})],1),_c('b-form-group',{attrs:{"label":"Cena brutto","label-for":"priceBrutto","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('priceBrutto')}},[_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","id":"priceBrutto"},on:{"change":function($event){return _vm.handleChangePrice($event)}},model:{value:(_vm.$v.form.priceBrutto.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.priceBrutto, "$model", $$v)},expression:"$v.form.priceBrutto.$model"}})],1),_c('b-form-group',{attrs:{"label":"Stawka vat","label-for":"vatValue","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('vatValue')}},[_c('b-form-select',{attrs:{"id":"vatValue","options":[
            { value: '', text: 'Wybierz' },
            { value: 0, text: '0%' },
            { value: 5, text: '5%' },
            { value: 8, text: '8%' },
            { value: 23, text: '23%' }
          ]},on:{"change":function($event){return _vm.handleChangeVatValue($event)}},model:{value:(_vm.$v.form.vatValue.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.vatValue, "$model", $$v)},expression:"$v.form.vatValue.$model"}})],1),_c('b-form-group',{attrs:{"label":"Cena netto","label-for":"price","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('price')}},[_c('b-form-input',{attrs:{"type":"number","id":"price","min":"0","step":"0.01","readonly":""},model:{value:(_vm.$v.form.price.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.price, "$model", $$v)},expression:"$v.form.price.$model"}})],1),_c('b-form-group',{attrs:{"label":"Kategoria","label-for":"category","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('category')}},[_c('b-form-select',{attrs:{"id":"category","options":[
            { value: '', text: 'Wybierz' },
            { value: 'podstawowa', text: 'Podstawowa' },
            { value: 'golebiowa', text: 'Gołębiowa' }
          ]},model:{value:(_vm.$v.form.category.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.category, "$model", $$v)},expression:"$v.form.category.$model"}})],1),_c('b-form-group',{attrs:{"label":"Ilość","label-for":"count","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('count')}},[_c('b-form-select',{attrs:{"id":"count","options":[
            { value: '', text: 'Wybierz' },
            { value: '0.5L', text: '0.5 L' },
            { value: '0.5kg', text: '0.5 kg' },
            { value: '1L', text: '1 L' },
            { value: '1kg', text: '1 kg' },
            { value: '5L', text: '5 L' },
            { value: '5kg', text: '5 kg' },
            { value: '10L', text: '10 L' },
            { value: '20kg', text: '20 kg' },
            { value: '25kg', text: '25 kg' }
          ]},model:{value:(_vm.$v.form.count.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.count, "$model", $$v)},expression:"$v.form.count.$model"}})],1),_c('b-form-group',{attrs:{"label":"Pojemność","label-for":"capacity","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('capacity')}},[_c('b-form-select',{attrs:{"id":"capacity","options":[
            { value: null, text: 'Wybierz' },
            { value: 500, text: '0.5' },
            { value: 1000, text: '1' },
            { value: 5000, text: '5' },
            { value: 10000, text: '10' },
            { value: 20000, text: '20' },
            { value: 25000, text: '25' }
          ]},model:{value:(_vm.$v.form.capacity.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.capacity, "$model", $$v)},expression:"$v.form.capacity.$model"}})],1),_c('b-form-group',{attrs:{"label":"Jednostka","label-for":"unit","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('unit')}},[_c('b-form-select',{attrs:{"id":"unit","options":[
            { value: '', text: 'Wybierz' },
            { value: 'KG', text: 'Kilogram' },
            { value: 'L', text: 'Litr' } ]},model:{value:(_vm.$v.form.unit.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.unit, "$model", $$v)},expression:"$v.form.unit.$model"}})],1),_c('p',[_vm._v("Obecne zdjęcie produktu:")]),_c('div',{staticClass:"preview-current-image mb-5"},[(_vm.form.imagePath !== '')?_c('img',{attrs:{"src":_vm.getBaseURL + _vm.form.imagePath,"alt":""}}):_vm._e()]),_c('b-form-group',{attrs:{"label":"Zdjęcie","label-for":"file"}},[_c('b-form-file',{attrs:{"id":"file","accept":"image/jpeg, image/png, image/gif","browse-text":"Przeglądaj","placeholder":"Nie wybrano pliku","required":""},on:{"change":function($event){return _vm.handleChangeFile($event)}}})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"isSlim"},model:{value:(_vm.form.isSlim),callback:function ($$v) {_vm.$set(_vm.form, "isSlim", $$v)},expression:"form.isSlim"}},[_vm._v(" Produkt Slim ")])],1),_c('b-form-group',{attrs:{"label":"Waga","label-for":"weight","invalid-feedback":"To pole jest wymagane","state":_vm.validateField('weight')}},[_c('b-form-input',{attrs:{"id":"input"},model:{value:(_vm.$v.form.weight.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.weight, "$model", $$v)},expression:"$v.form.weight.$model"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }